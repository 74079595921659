.pages {
    background-color: #FFFAF1;
    padding: 0;
    height: calc(100vh - 80px);

    .polygon_admin {
        top: 0;
        right: 0;
        position: absolute;
    }
}