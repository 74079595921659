.navigation-bar {
  .right-container {
    nav {
      .user-account {
        width: 30px;
        height: 30px;

        button {
          width: 30px;
          height: 30px;
        }
      }

      .navbar-brand {
        gap: 1rem;
        display: flex;
        align-items: center;
      }
    }
  }
}

.hover-underline-animation {
  // display: inline-block;
  position: relative;
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1.5px !important;
  bottom: 0;
  left: 0;
  background-color: white;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

logo-slider {
   
  $max-image-size: 400px;
  
  --image-size: #{$max-image-size};
  
  width: 100vw;
	position: relative;
	display: flex;

	&:hover div {
		animation-play-state: paused;
	}
	
	div { 
		display: flex;
		position: relative;
		animation: marquee 60s linear infinite;
    justify-content: space-around;
    align-items: flex-end;
    
    // // Debug: This is just to help see where the loop happens
    // &:nth-of-type(1) { &, img { border:1px dashed red;  }}
    // &:nth-of-type(2) { &, img { border:1px dashed blue; }}
    
    // You may need this if there are too few images for the banner width
    // min-width:100%;
    
	}
		
	img {
		display: block;
		min-width: var(--image-size);
		height: fit-content;
		margin: 0 1vw;
    bottom: 0;
	}
  
  &:after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
	}
  
  @media (max-width:900px) {
    --image-size: 200px;
	}

}

@keyframes marquee {
	0%   { transform: translateX(0%); }
	100% { transform: translateX(-100%); }
}
