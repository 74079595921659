.loginContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;

  .imageBG {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .loginForm {
    gap: 1rem;
    margin: auto;
    width: 30rem;
    padding: 2.5rem;
    display: flex;
    border-radius: 0.5rem;
    flex-direction: column;

    img {
      width: 80%;
      margin: auto;
    }

    label {
      font-weight: bold;
    }

    .marginContainer {
      gap: 10px;
      width: 100%;
      display: flex;
      flex-direction: column;

      a {
        font-size: 14px;
        color: #EFC72F;
        font-weight: bold;
        text-decoration: none;
        text-align: right;
      }

      button {
        margin-top: 1rem;
        width: 100%;
        border: 0;
        background-color: #EFC72F;
      }
    }
    .form_input_lg {
      font-size: 0.9rem;
    }
  }
}
