@font-face {
    font-family: 'Rota-Medium';
    src: local('Rota-Medium'), url(../../assets/fonts/Rota-Medium.otf) format('opentype');
  }
  
  @font-face {
    font-family: 'Rota-SemiBold';
    src: local('Rota-SemiBold'), url(../../assets/fonts/Rota-SemiBold.otf) format('opentype');
  }
  
  @font-face {
    font-family: 'Rota-Bold';
    src: local('Rota-Bold'), url(../../assets/fonts/Rota-Bold.otf) format('opentype');
  }

.contact {
    font-family: "Rota-Medium", sans-serif;
    font-size: 34px !important;
    color: #0B0E3F;
    .container {
        margin: 50px;
        margin-top: 240px;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;

        .heading {
            font-family: "Rota-SemiBold", sans-serif;
            max-width: 743px;
            font-size: 4.384vw;
            font-style: normal;
            line-height: 120%;
            
            p {
                margin: 0;
            }
        }

        .contact_details{
            p, a {
                margin: 0;
                font-size: 2vw;
            }
            div:not(:first-child):not(:last-child) {
                margin: 38px 0;
            }
            .editNSave {
                position: absolute;
                font-size: 1rem;
                right: 0;
                z-index: 3;
                background-color: #EFC72F;
                padding: 1vw;
                border-radius: 10px;
                opacity: .5;
                line-height: 1;
            }
        }
    }

    .polygon1 {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
    }

    .polygon2 {
        width: 296px;
        top: 21rem;
        transition: 0.5s;
    }

    .contact_form {
        position: relative;
        display: block;
        margin: auto;

        form {
            max-width: 1192px;
            width: 100%;
            margin: auto;

            label {
                margin-bottom: 1.768vw;
                font-size: 2vw;
            }
    
            input,textarea {
                max-width: 1192px;
                border-radius: 9.676px;
                border: 2.419px solid #E2E8F0;
                background: #FFFFFF;
                width: 100%;
                font-size: 2vw;
            }
    
            input:focus-visible,
            textarea:focus-visible {
                outline: 2.419px solid #EFC72F;
            }
    
            input {
                height: 4.899vw;
                padding: 0px 1.955vw;
                margin-bottom: 1.768vw;
            }
    
            textarea {
                height: 15.051vw;
                padding: 20px 1.955vw;
            }

            div {
                text-align: center;
    
                button {
                    display: inline-flex;
                    height: 4.040vw;
                    padding: 0px 2.02vw;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
                    color: #FFF;
                    font-family: Arial;
                    font-size: 1.515vw;
                    font-style: normal;
                    font-weight: 600;
                }
            }
        }

        .polygon3 {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: -1;
        }
    }

    .solutions_link {
        text-decoration: underline;
        color: #FAFAFA;
        font-size: 5.384vw;
        line-height: 120%;
    }
}

@media screen and (max-width: 1199px) {
    .contact {
        .polygon2 {
            width: 232px;
            top: 19rem;
        }
        .heading {
            line-height: 4vw;
            font-size: 4.75vw;
        }  
        .contact_form {
            padding: 50px;
        }
    }
  }

@media screen and (max-width: 959px) {
    .contact {
        .polygon2 {
            width: 200px;
            top: 18rem;
        }

        .heading {
            line-height: 4vw;
            font-size: 5.25vw;
        } 

        .contact_form {
    
            form {

                label, input, textarea {
                    font-size: 20px !important;
                }

                input {
                    padding: 0px 16px;
                    margin-bottom: 35px;
                }
        
                textarea {
                    padding: 8px 16px;
                }
        
                div {
        
                    button {
                        height: 80px;
                        padding: 20px 40px;
                        font-size: 20px;
                    }
                }
            }
        }
    }
  }
  

@media screen and (max-width: 767px) {
    .contact {
      .container {
        margin: 1rem !important;
        margin-top: 208px !important;
      }
      .polygon2 {
        width: 25vw;
        top: 17rem;
    }
    .heading {
        line-height: 6vw;
        font-size: 67px !important;
    } 
    .contact_details {
        p, a {
            font-size: 29px !important;
        }
        input {
            height: 97px;
            padding: 0px 38.703px;
            margin-bottom: 35px;
        }

        textarea {
            height: 298px;
            padding: 20px 38.703px;
        }

    }
    .contact_form {
        padding: 1rem !important;
        form {
            label, input, textarea {
                font-size: 29px !important;
            }

            input {
                height: 97px;
                padding: 0px 38.703px;
                margin-bottom: 35px;
            }
    
            textarea {
                height: 298px;
                padding: 20px 38.703px;
            }

            div {
        
                button {
                    height: 80px;
                    padding: 20px 40px;
                    font-size: 29px;
                }
            }
        }
    } 
    }

    .solutions_link {
        font-size: 10.384vw !important;
    }
  }

  
@media screen and (max-width: 490px) {
    .contact {
      .polygon2 {
        width: 40vw;
        top: 23rem !important;
    }
    .solutions_link {
        font-size: 12vw;
    }
    .heading {
        line-height: 8vw;
        font-size: 9vw;
    } 
    .contact_details {
        p, a {
            font-size: 24px !important;
        }
    } 
    }
  }