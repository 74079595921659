

.home {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 0 0 3% 0;
    position: relative;
    overflow: hidden;

    .circleCursor {
        width: 10vw;
        height: 10vw;
        border-radius: 100%;
        background-color: #EFC72F;
        position: absolute;
        top: 0;
        left: 0;
    }
    
    .initialAnimation {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #1A202C;
        color: #fafafa;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        font-size: 15vw;
    }
    
    .mainText {
        font-size: 15vw;
        text-align: center;
        color: #EFC72F;
    }
}

.section2, .section5 {
    hr{
        opacity: 1;
    }
}

.section2 {
    height: 100%;
    width: 100vw;
    padding: 10% 5% 10% 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h5:first-of-type {
        width: 100%;
    }

    p {
        // font-size: 4.6rem;
        line-height: 110%;
       
    }

    .contentCont {
        display: flex;
        flex-wrap: wrap;
        .section2Top {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            

            span {
                line-height: 130%;
                margin-left: 0;
                color: #1A202C;
                text-indent: 0%;   
                display: flex;
                flex-wrap: wrap; 
                width: fit-content;
            }

            .topContainer {
                display: flex;
                flex-direction: column;
                width: 100%;

                p {
                    margin-bottom: 0;    
                }
                
                // border-bottom: 1px black solid;
                // padding-bottom: 1
            }
        }

        .section2Bottom {
            p {
                font-size: 3.9vw;
                text-indent: 7vw;

                span {
                    color: #EFC72F;
                }
            }
        }

        span {
            position: relative;
            overflow: hidden;
            span {
                span {
                    margin-left: 10px;
                    color: #EFC72F;
                    text-indent: 10%;
                }

            }
        }

    }
}

.section3 {
    height: 100%;
    width: 100vw;
    display: flex;
    justify-content: center;
    padding: 10% 5% 10% 5%;
    p {
        text-align: center;
        font-size: 5vw;
        line-height: 110%;
        color: #EFC72F;
        display: flex;
        flex-direction: column;

        span {
            position: relative;
            overflow: hidden;
            padding: 8px 0;

            span {
                padding: 0;
                border-bottom: 3px solid #EFC72F;
            }
        }
    }
}

.section4 {
    height: 100%;
    width: 100vw;
    padding: 10% 5% 10% 5%;
    display: flex;
    gap:2rem;
    flex-direction: column;
    justify-content: center;

    @media screen and (min-width: 992px) {
        
        height: 100vh;
        padding: 8% 5% 8% 5%;

    }

    h5 {
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 110%;
        width: 100%;
    }

    h5:first-of-type {
        width: 50%;
    }

    .topContainer {
        display: flex;
        justify-content: space-between;
        // border-bottom: 1px black solid;
        // padding-bottom: 10px;
        // margin-bottom: 20px;
        align-items: flex-end;
    }

    .icon_box {
        display: flex;
        flex-direction: row-reverse;
        gap: 6%;
        width: 25vw;
        align-items:center ;

        h5 {
            margin-bottom: 0;
        }

        svg {
            margin-top: 1;
        }
    }
}

.section5 {
    height: 100%;
    width: 100vw;
    padding: 10% 5% 10% 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h5 {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 110%
    }

    .topContainer {
        margin-bottom: 5%;
        width: 100%;
        font-size: 2.125rem;
        span {
            position: relative;
            overflow: hidden;
        }
    }

    .counterContainer {
        margin-left: 5%;
        font-size: 5.5vw;
        line-height: 110%;
        // display: flex;
        // flex-direction: column;
        // flex-wrap: wrap;
        position: relative;

        .editNSave {
                position: absolute;
                font-size: 1rem;
                right: 0;
                z-index: 3;
                background-color: #EFC72F;
                padding: 1vw;
                border-radius: 10px;
                opacity: .5;
            }
        span {
            color: #EFC72F;
        }
        
    }

    

    
}

.section6 {
    padding: 0 5%;

    .header {
        font-size: 5vw;
        color: #EFC72F;
        padding: 0;
        line-height: 110%;
        width: 70%;
    }

    .slider {
        padding: 5% 0;
    }
}


