@font-face {
    font-family: 'Rota-Medium';
    src: local('Rota-Medium'), url(../../assets/fonts/Rota-Medium.otf) format('opentype');
}

@font-face {
    font-family: 'Rota-SemiBold';
    src: local('Rota-SemiBold'), url(../../assets/fonts/Rota-SemiBold.otf) format('opentype');
}

@font-face {
    font-family: 'Rota-Bold';
    src: local('Rota-Bold'), url(../../assets/fonts/Rota-Bold.otf) format('opentype');
}

.sidebar, .header {
    font-family: "Rota-SemiBold", sans-serif;
}
