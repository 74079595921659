// GLOBAL SCSS.
$mainColor: #168a3e;
$lightMainColor: #97e09b;

html {
  font-family: Arial;
}

input.form-control {
  &:focus {
    border-color: $mainColor;
    box-shadow: 0 0 0 0.25rem $lightMainColor;
  }
}
