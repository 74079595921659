header{
  position: fixed;
  top: 0;
  width: 100%;
  height: 10vh;
  padding: 0 10% 0 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  z-index: 10;

  .logo {

    img {
      height: 5vh;
    }
  }

  .menu {
    cursor: pointer;
  }

  nav{
    width: 100%;
    height: 50vh;
    background-color: #111111;
    position: absolute;
    left: 0;
    bottom: 10vh; // change between -40vh & 10vh
    color: #eeeeee;
    z-index: 5;

    .secondContainer {
      height: 10vh;
      width: 100%;
      padding: 0 10% 0 10%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .close{
        cursor: pointer;
      }

      .secondLogo {

        img {
          height: 5vh;
        }
      }
    }
    .navigationContent {
      height: 40vh;
      padding: 0 10% 0 10%;
      display: flex;
      justify-content: space-between;

      .slogan {
        width: 50%;
        position: relative;

        p {
          white-space: nowrap;
          position: absolute;
          bottom: 10%;
        }
      }
      .links {
        width: 50%;
        height: 90%;
        padding-left: 0 0 10% 10%;
        display: grid;
        grid-template-columns: auto auto auto auto;

        div {
          cursor: pointer;
        }

        :nth-child(1),
        :nth-child(2),
        :nth-child(3),
        :nth-child(4) {
          grid-column-start: 1;
          grid-column-end: 5;
          font-size: 2.4rem;
        }

        :nth-child(5), 
        :nth-child(6), 
        :nth-child(7) {
          grid-row-start: 5;
          grid-row-end: 6;
          display: flex;
          justify-content: flex-end;
        }

        :nth-child(5) {
          grid-column-start: 2;
          grid-column-end: 3;
        }
        :nth-child(6) {
          grid-column-start: 3;
          grid-column-end: 4;
        }
        :nth-child(7) {
          grid-column-start:4;
          grid-column-end: 5;
        }
      }
    }
  }
}
