@font-face {
    font-family: 'Rota-Medium';
    src: local('Rota-Medium'), url(../../assets/fonts/Rota-Medium.otf) format('opentype');
  }
  
  @font-face {
    font-family: 'Rota-SemiBold';
    src: local('Rota-SemiBold'), url(../../assets/fonts/Rota-SemiBold.otf) format('opentype');
  }
  
  @font-face {
    font-family: 'Rota-Bold';
    src: local('Rota-Bold'), url(../../assets/fonts/Rota-Bold.otf) format('opentype');
  }

.footer {
    font-family: "Rota-Medium", sans-serif;
    font-size: 24px;

    .get-in-touch {
        font-size: 34px;
    }

    .contact-button {
        width: 162px;
        height: 86px;
        border-radius: 15px;
        border: 3px solid #FAFAFA;
        font-size: 24px;
    }

    .footer-contact {
        .footer-address > p {
            margin: 0;
        }
    }
    
    .hsi {
        font-family: "Rota-Bold", sans-serif;
        font-size: 15.19vw;
        text-align: center;
        margin-top: -40px;
    }
}

@media screen and (max-width: 1199px) {
    .hsi {
        margin-top: -20px !important;
    }
  }

  @media screen and (max-width: 769px) {
    .hsi {
        font-size: 14.9vw !important;
    }
  }

@media screen and (max-width: 479px) {
    .hsi {
        margin-top: 0 !important;
    }
  }